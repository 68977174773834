


























































import {
  computed,
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import SimpleButton from '~/components/common/SimpleButton.vue';
import { MODAL_NAMES } from '~/components/common/SimpleModal';
import RatingStars from '~/components/RatingStars/RatingStars.vue';
import SwitchAndSaveLabel from '~/components/SwitchAndSave/components/Label.vue';
import { useMagentoConfiguration, useSwitchAndSell } from '~/composables';
import { ProductInterface } from '~/modules/GraphQL/types';

import SwitchAndSellInfo from '../Info.vue';

export default defineComponent({
  name: 'SwitchAndSellContent',
  components: {
    SimpleButton,
    SwitchAndSaveLabel,
    RatingStars,
    SwitchAndSellInfo,
  },
  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      default: null,
    },
    quantity: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const {
      altProduct, infoContent, infoType, priceDifference, handleSwitchProduct, toggleAfterAddToCartModal,
    } = useSwitchAndSell(props.product);
    const { currentCurrencySymbol } = useMagentoConfiguration();

    const switchText = computed(() => {
      if (props.product.price?.minimalPrice?.amount?.value > altProduct.value.price?.minimalPrice?.amount.value && infoType.value === 1) {
        const difference = altProduct.value.price_difference_incl_tax || altProduct.value.price_difference;

        if (!difference) return 'Switch and Save';

        return `Switch and Save ${currentCurrencySymbol.value}${difference.toFixed(2)}`;
      }

      return 'Switch and Save';
    });

    const currentRating = computed(() => (!props.product.rating_summary ? 0 : +((props.product.rating_summary * 5) / 100).toFixed(1)));
    const reviewsTotal = computed(() => props.product.review_count);

    const cancelSwitch = () => {
      toggleAfterAddToCartModal(props.product, props.quantity);
    };

    return {
      altProduct,
      infoType,
      infoContent,
      currentRating,
      reviewsTotal,
      priceDifference,
      switchText,
      name: MODAL_NAMES.switchAndSell,
      handleSwitchProduct,
      cancelSwitch,
    };
  },
});
