



























































































import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';

import SwitchAndSaveContent from '~/components/SwitchAndSave/components/Content/Content.vue';
import isServer from '~/helpers/isServer';

export default defineComponent({
  name: 'SwitchAndSavePage',
  components: {
    SwitchAndSaveContent,
  },
  setup() {
    const metaInfo = computed(() => {
      const description = [{
        hid: 'description',
        name: 'description',
        content: 'Why switch to Cartridge Save own-brand?',
      }];

      if (!isServer) {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        document.documentElement.style.setProperty('--scrollBarWidth', `${scrollbarWidth || 0}px`);
      }

      return {
        title: 'Why switch to Cartridge Save own-brand?',
        meta: description,
      };
    });

    return {
      metaInfo,
    };
  },
  data() {
    return {
      title: 'Why switch to Cartridge Save own-brand?',
    };
  },
  head() {
    return this.metaInfo;
  },
});
